import { Box, Center, ChakraProvider, Heading, Img } from "@chakra-ui/react";
import { motion } from "framer-motion";
import * as React from "react";
import { createRoot } from "react-dom/client";
import range from "lodash.range";
import "./styles.css";

const MotionBox = motion(Box);
const MotionHeading = motion(Heading);

const MaskBars = ({ count = 12, children }) => {
  return (
    <Box pos="relative" w="fit-content" h="fit-content">
      {React.cloneElement(children(0), { visibility: "hidden" })}
      {range(count).map((i) => {
        const start = (100 / count) * i;
        const end = (100 / count) * (i + 1);
        return (
          <MotionBox
            key={i}
            pos="absolute"
            inset={0}
            style={{
              WebkitMaskImage: `linear-gradient(to right, rgba(0,0,0,0) ${start}%, rgba(0,0,0,1) ${start}%, rgba(0,0,0,1) ${end}%, rgba(0,0,0,0) ${end}%)`,
            }}
          >
            {children(i)}
          </MotionBox>
        );
      })}
    </Box>
  );
};

function App() {
  return (
    <Center h="100vh" bg="#000" overflow="hidden">
      <MaskBars count={12}>
        {(index) => (
          <MotionHeading
            px={44}
            animate={{
              x: [-160, 160, -160],
              skewY: [-5, 5, -5],
              scale: [1.2, 0.9, 1.2, 0.7, 1.2],
            }}
            transition={{
              repeat: Infinity,
              duration: 3.8,
              delay: index * 0.1,
              ease: "easeInOut",
            }}
            fontSize="25vh"
          >
            Fingere
          </MotionHeading>
        )}
      </MaskBars>
    </Center>
  );
}

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <React.StrictMode>
    <ChakraProvider>
      <App />
    </ChakraProvider>
  </React.StrictMode>
);
